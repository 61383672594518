'use client';
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import Image, { getImageProps } from 'next/image';
import Link from 'next/link';

import { ReactComponent as ArrowRightBlack } from '@/app/_icons/Ck_arrow_right_black24px.svg';
import { ImageTransformationKeys } from '@/types/files/files.types';
import { CallToAction } from '@/types/home/home.types';
import { directusImageLoader } from '@/utils/loaders';

import LinkCustom from '../link/LinkCustom';

import Styles from './CTASection.module.scss';

type Props = {
  call_to_actions: CallToAction[];
  imageKeys: ImageTransformationKeys;
};

const CTASection = ({ call_to_actions, imageKeys }: Props) => {
  let mainSectionOfCTA: CallToAction = {
    text: '',
    url: '',
    type: '',
    icon: null,
    image: null,
    heading: null,
    subheading: null,
    status: '',
  };
  const filteredCallToActions = call_to_actions?.filter((cta) => {
    if (cta?.image) {
      mainSectionOfCTA = cta;
      return false;
    }
    return true;
  });

  // main large CTA
  const commonImgProps = {
    alt: 'cta',
    width: 640,
    height: 360,
    loader: directusImageLoader,
  };
  const mainImageSrc = mainSectionOfCTA?.image || '';
  const {
    props: { srcSet: mobile },
  } = getImageProps({
    ...commonImgProps,
    src: `${mainImageSrc}?key=${imageKeys.mobileKey}`,
  });
  const {
    props: { srcSet: desktop, ...imgProps },
  } = getImageProps({
    ...commonImgProps,
    src: `${mainImageSrc}?key=${imageKeys.desktopKey}`,
  });

  return (
    <Box className={Styles.CTASectionWrapper}>
      <SimpleGrid columns={2} className={Styles.CTASectionLeft}>
        {filteredCallToActions?.map((cta, index) => (
          <Box key={index} className={Styles.toolsCard}>
            <Image
              src={cta?.icon || ''}
              loader={directusImageLoader}
              alt="cta"
              className={Styles.toolIcon}
              width={90}
              height={90}
            />
            <Link className={Styles.themeLink} href={cta?.url}>
              <span>{cta?.text}</span>
              <ArrowRightBlack className={Styles.menuIcon} />
            </Link>
          </Box>
        ))}
      </SimpleGrid>

      <Box
        className={Styles.financeSection}
        mx={{ base: 'auto', md: 'initial' }}
      >
        <Box
          className={Styles.financeContent}
          mx={{ base: 'auto', md: 'initial' }}
        >
          <Box className={Styles.financeCarImgWrap}>
            <picture>
              <source media="(max-width: 575px)" srcSet={mobile} />
              <source media="(min-width: 576px)" srcSet={desktop} />
              <img {...imgProps} alt="award" className={Styles.financeCarImg} />
            </picture>
          </Box>
          <Box
            className={Styles.headingElement}
            textAlign={{ base: 'left', md: 'center', lg: 'left' }}
          >
            <Link
              // _hover={{ textDecoration: 'none' }}
              href={mainSectionOfCTA?.url}
            >
              <Heading
                as="h3"
                className={Styles.titleLg}
                marginBottom={{ base: 4, md: 5 }}
              >
                {mainSectionOfCTA?.heading}
              </Heading>
            </Link>
            <Text className={Styles.titleText}>
              {mainSectionOfCTA?.subheading}
            </Text>
            <LinkCustom
              linkText={mainSectionOfCTA?.text}
              className="btnOutline"
              href={mainSectionOfCTA?.url}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CTASection;
